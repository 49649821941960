<template>
  <div :class="['h-100', 'player-bg', 'relative']" v-show="channels.length > 0" :style="{ backgroundImage: 'url(\'' + playerBg() + '\')' }">
    <div :class="['page-overlay absolute', isAdvertPlaying ? 'd-block' : 'd-none']"></div>
    <navigation class="d-none d-lg-block"/>
    <div class="d-block d-lg-none primary-fill pointer down-icon" style="">
      <svg
        @click="playerShow = !playerShow"
        :class="[!playerShow ? 'mirrory' : '']"
        version="1.1"
        id="Layer_1"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 491.996 491.996"
        style="enable-background: new 0 0 491.996 491.996"
        xml:space="preserve"
      >
        <g>
          <path
            d="M484.132,124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86c-7.208,0-13.964,2.792-19.036,7.86l-183.84,183.848
			L62.056,108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968,2.788-19.036,7.856l-16.12,16.128
			c-10.496,10.488-10.496,27.572,0,38.06l219.136,219.924c5.064,5.064,11.812,8.632,19.084,8.632h0.084
			c7.212,0,13.96-3.572,19.024-8.632l218.932-219.328c5.072-5.064,7.856-12.016,7.864-19.224
			C491.996,136.902,489.204,130.046,484.132,124.986z"
          />
        </g>
      </svg>
    </div>
    <div v-if="!playerShow" class="player-hidden-text d-lg-none">
      <div class="ml-2 player-song" style="white-space: nowrap">
        <div class="song-ellipse-cont">
          <span v-if="isChannel() || isSong()">
            <b v-if="isChannel()">{{ channel.name }}</b>

            {{ getTitle() == '' ? '' : '-' + getTitle() }}
          </span>
        </div>
      </div>
    </div>

    <div :class="[!isSong() || (isAdvertPlaying) ? 'inactive' : '', 'px-3', 'primary--text', 'pb-2', 'pt-0']">
      <b-aspect :aspect="1" class="text-center primary--text" style="padding: 7%">
        <div class="player-circle" :style="{}">
          <div class="player-content relative h-100">
            <div class="relative h-100">
              <div class="player-title" style="margin-bottom: 0.2vw">
                <div class="title-ellipse">
                  <span>
                    <b v-if="isChannel()">{{ channel.name }}</b>
                  </span>
                </div>
              </div>
              <div class="player-play pointer">
                <div class="play-icon" v-show="!played" @click="play()"></div>
                <div v-show="played" class="pause-icon" @click="play()"></div>
              </div>
              <div class="progress-cont" ref="progress">
                <div class="progress__time">
                  <span>{{ currentTime }}</span>
                  <span class="relative">/ {{ readyDuration ? duration : '00:00' }}</span>
                </div>
                <div class="progress__bar" @click="clickProgress">
                  <div class="progress__current" :style="{ width: barWidth }"></div>
                </div>
              </div>
              <div v-if="!isStreamChannel"
                   class="next-icon pointer" @click="getSong('btn')">
                <svg
                  class=""
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 416.004 416.004"
                  style="enable-background: new 0 0 416.004 416.004"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M344.3,223.6L18.8,1.8l-0.3-0.2c-1.4-1.1-7.1-2.6-12-0.5C2.6,2.8,0.4,6.5,0,12.4v443.8l0,0.3c-0.2,2.8,1.7,7.2,5.5,9.7
				c3.8,2.5,8.6,2.4,14-0.3l325.6-221.8l0.5-0.2c1.2-0.6,4.4-3.8,4.9-8C351,231.8,348.9,227.7,344.3,223.6z M25.1,431V36l291.1,198
				L25.1,431z"
                        />
                      </g>
                    </g>
                  </g>
                  <rect x="350.6" width="26" height="468"/>
                </svg>
              </div>
              <div class="player-song large-song" v-if="isSong()">
                <div class="song-ellipse-cont">
                  <span>{{ getTitle() }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="player-placeholder" v-if="isAdvertPlaying"></div>
        </div>
      </b-aspect>
    </div>
    <div class="clock d-none d-lg-block">
      <b-aspect :aspect="16 / 11">
        <span>{{ time }}</span>
      </b-aspect>
    </div>
  </div>
</template>

<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';
import Navigation from '@/partials/Navigation';
import { EventSourcePolyfill } from 'event-source-polyfill';

export default Vue.extend({
  data() {
    return {
      windowW: 0,
      windowH: 0,
      song: {},
      audio: null,
      played: false,
      isAdvertPlaying: false,
      circleLeft: null,
      barWidth: null,
      duration: null,
      currentTime: null,
      pausedCurrentTime: 0.0,
      isTimerPlaying: false,
      readyDuration: false,
      time: '00:00',
      adverts: [],
      advertsInterval: null,
      volumeInterval: null,
      eventSource: null,
    };
  },
  components: { Navigation },
  watch: {
    advertsInterval(val) {
      this._debug('change interval', val);
    },

    song: {
      handler(val) {
        this._debug('song changed', val);
        this.audio.src = this.song.url;
        this.audio.load();
        this.play();
      },
      deep: true
    },
    adverts: {
      handler(val) {
        this._debug('advert changed', val);
        this.playAdvert();
      },
      deep: true
    },
    channel: {
      handler(val) {
        this._debug('channel changed in player', val);
        if (Object.keys(val).length > 0) {
          this.getSong();
        }
      },
      deep: true
    },
    isLoggedIn(val) {
      if (!val) {
        this.resetAudio();
      }
    }
  },
  beforeDestroy() {
    this.resetAudio();
  },
  destroyed: function () {
    this.resetAudio();
  },
  mounted() {
    this.getSizes();
    window.addEventListener('resize', this.getSizes);
    this.showTime();
    this.initAudio();

    if (this.playChannel && Object.keys(this.channel).length > 0) {
      this.getSong();
    }
  },
  computed: {
    isDebug() {
      return this._isDebug();
    },
    channels: {
      get() {
        return this.$store.state.channels.channels;
      },
      set(value) {
        this.$store.commit('channels/setChannels', value);
      }
    },
    playChannel() {
      return this.$store.state.channels.playChannel;
    },
    playerShow: {
      get() {
        return this.$store.state.player.playerShow;
      },
      set(value) {
        this.$store.commit('player/setShow', value);
      }
    },
    channel: {
      get() {
        return this.$store.state.channels.channel;
      },
      set(value) {
        this.$store.commit('channels/setChannel', value);
      }
    },

    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    id() {
      return this.$store.state.channels.channel.id;
    },
    isStreamChannel() {
      return typeof this.channel === 'object' && Object.keys(this.channel).length > 0 && !!this.channel.streamUrl;
    },
  },

  created() {
  },

  methods: {
    playAudio(type) {
      let promise = this.audio.play();
      if (promise !== undefined) {
        promise.then(_ => {
          // console.log('Autoplay started: ' + type);
        }).catch(error => {
          console.log('Autoplay was prevented: ' + type);
        });
      }
    },
    resetAudio() {
      clearInterval(this.advertsInterval);
      this.advertsInterval = null;
      if (this.audio !== null) {
        this.audio.pause();
      }
      this.song = {};
      this.audio = null;
    },
    playerBg() {
      if (this.isSong()) {
        if (this.channel.image) return this.channel.image;

        return '';
      } else {
        return '';
      }
    },
    getAdverts() {
      const this_ = this;
      this_._debug('getadverts');
      apiClient
        .get('api/xyz?time=' + new Date().getTime() + (this.isStreamChannel ? '&is_stream=1' : ''), {})
        .then(response => {
          // if (this.isDebug) {
          //   this_.adverts = this.adverts.concat([
          //     {
          //       id: 47051,
          //       type: 'ad',
          //       url: 'https://www.kozco.com/tech/piano2-CoolEdit.mp3',
          //       hash: '8f1deac6858027e24c39e248469fdf88',
          //     },
          //     // {
          //     //   id: 47052,
          //     //   type: 'ad',
          //     //   url: 'https://www.kozco.com/tech/piano2-CoolEdit.mp3',
          //     //   hash: '8f1deac6858027e24c39e248469fdf88',
          //     // },
          //   ]);
          // }

          this_._debug('getadverts response', response.data);
          if (Array.isArray(response.data) && response.data.length > 0) {
            this_._debug('getadverts array', response.data);
            this_.adverts = this_.adverts.concat(response.data);
          }
        })
        .catch(error => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center'
          });
        });
    },
    initAudio() {
      let vm = this;
      this.audio = new Audio();
      this.audio.ontimeupdate = function () {
        if (vm.audio !== null) vm.generateTime();
      };
      this.audio.onloadedmetadata = function () {
        vm.generateTime();
      };
      this.audio.onended = function () {
        if (vm.isAdvertPlaying) {
          vm.endAdvert();
        } else {
          vm.getSong();
          this.isTimerPlaying = true;
        }
      };
      this.audio.onpause = function () {
        vm.played = false;
        vm.$store.commit('channels/setPlayChannel', false);

        if (vm.advertsInterval !== null) {
          vm._debug('pause clear interval advert');
          clearInterval(vm.advertsInterval);
          vm.advertsInterval = null;
        }
      };
      this.audio.onplay = function () {
        vm.played = true;
        vm.$store.commit('channels/setPlayChannel', true);

        if (vm.advertsInterval !== null) {
          clearInterval(vm.advertsInterval);
          vm.advertsInterval = null;
        }
        const timeout = vm.isDebug ? 30 * 1000 : 60 * 1000;
        if (!this.isAdvertPlaying) {
          vm.advertsInterval = setInterval(() => {
            vm.getAdverts();
          }, timeout);
        }
      };
    },
    getTitle() {
      if (typeof this.song.title === 'undefined') return '';
      if (typeof this.isStreamChannel) return this.channel.name;
      return this.song.artist !== 'Ismeretlen előadó' ? this.song.artist + ' ⁠–⁠ ' + this.song.title : this.song.title;
    },
    generateTime() {
      let width = (100 / this.audio.duration) * this.audio.currentTime;
      this.barWidth = width + '%';
      this.circleLeft = width + '%';
      let durmin = Math.floor(this.audio.duration / 60);
      let dursec = Math.floor(this.audio.duration - durmin * 60);
      let curmin = Math.floor(this.audio.currentTime / 60);
      let cursec = Math.floor(this.audio.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = '0' + durmin;
      }
      if (dursec < 10) {
        dursec = '0' + dursec;
      }
      if (curmin < 10) {
        curmin = '0' + curmin;
      }
      if (cursec < 10) {
        cursec = '0' + cursec;
      }
      this.duration = durmin + ':' + dursec;
      this.currentTime = curmin + ':' + cursec;
      this.readyDuration = true;
      if (isNaN(durmin) || isNaN(dursec)) {
        this.readyDuration = false;
      }
    },
    setPlayChannel(channel = true) {
      this._debug('setplaychannel in player', channel, !channel);
      if (channel) {
        this.$store.commit('channels/setPlayChannel', true);
      } else {
        this.$store.commit('channels/setChannel', {});
        this.$store.commit('channels/setPlayChannel', false);
      }
    },
    updateBar(x) {
      let progress = this.$refs.progress;
      let maxduration = this.audio.duration;
      let position = x - progress.offsetLeft;
      let percentage = (100 * position) / progress.offsetWidth;
      if (percentage > 100) {
        percentage = 100;
      }
      this._debug('perc', progress, position, x);
      if (percentage < 0) {
        percentage = 0;
      }
      this.barWidth = percentage + '%';
      this.circleLeft = percentage + '%';
      this.audio.currentTime = (maxduration * percentage) / 100;
      this.playAudio('updateBar');
    },
    clickProgress(e) {
      if (this.isSong()) {
        this.isTimerPlaying = true;
        this.audio.pause();
        this.updateBar(e.pageX);
      }
    },
    isChannel() {
      return typeof this.channel === 'object' && Object.keys(this.channel).length > 0;
    },

    isSong() {
      return typeof this.song === 'object' && Object.keys(this.song).length > 0;
    },

    play() {
      this._debug('set play');

      if (this.isSong()) {
        if (this.audio.paused) {
          this._debug('audio paused', this.audio);
          this.playAudio('play');
          this.isTimerPlaying = true;
        } else {
          this.audio.pause();
          this.isTimerPlaying = false;
        }
      }
    },
    pauseMusic() {
      this._debug('pauseMusic');

      this.isAdvertPlaying = true;
      this.pausedCurrentTime = this.audio.currentTime;
    },
    resumeMusic() {
      this._debug('resumeMusic, paused at: ' + this.pausedCurrentTime);

      this.isAdvertPlaying = false;
      this.audio.src = this.song.url;
      this.audio.currentTime = parseFloat(this.pausedCurrentTime);
      this.pausedCurrentTime = 0.0;
      this.audio.load();
      this.playAudio('resumeMusic');
    },
    playAdvert() {
      if (this.adverts.length > 0 && this.audio.src !== this.adverts[0].url) {
        this._debug('playAdvert');

        this.pauseMusic();

        this.audio.src = this.adverts[0].url;
        this.audio.load();
        this.playAudio('playAdvert');
      }
    },
    endAdvert() {
      this._debug('endAdvert');

      const advert = Object.assign({}, this.adverts[0]);
      this.adverts.shift();
      if (this.adverts.length < 1) {
        this.resumeMusic();
        this.playAudio('endAdvert');
      }
      apiClient
        .post(`/api/xyz/${advert.id}/finished`, {})
        .then(response => {
          this._debug('endadvert', response);

          if (Array.isArray(response.data)) {
          }
        })
        .catch(error => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center'
          });
        });
    },

    getSizes() {
      this.windowW = window.innerWidth;
      this.windowH = window.innerHeight;
      this.carouselW = this.sidebar ? this.windowW * 0.7 : this.windowW;
    },

    getSong(next = null) {
      this._debug('getsong');
      const this_ = this;

      if (this.isStreamChannel) {
        this._debug('start stream', next);
        this.song = {
          'id': this.channel.id,
          'type': 'music',
          'url': this.channel.streamUrl,
          'hash': '',
          'liked': false,
          'title': this.channel.name,
          'artist': this.channel.description
        };
      } else if (this.isChannel()) {
        this._debug('next', next);
        apiClient
          .get(`/api/channels/${this.id}/next/STATUS_FINISHED`, {})
          .then(response => {
            this._debug('song', response);
            if (response.data) {
              this_.song = response.data;
            }
          })
          .catch(error => {
            this.$root.$bvToast.toast(error.message, {
              title: this.$i18n.t('_error'),
              variant: 'danger',
              solid: true,
              noAutoHide: true,
              toaster: 'b-toaster-top-center'
            });
          });
      }
    },

    showTime() {
      let date = new Date(),
        h = date.getHours(), // 0 - 23
        m = date.getMinutes(), // 0 - 59
        s = date.getSeconds(); // 0 - 59

      h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;

      var time = h + ':' + m;
      this.time = time;

      setTimeout(this.showTime, 1000);
    },
  }
});
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.player-bg {
  background-size: cover;
  background-color: #525252;
  background-position: center;
  /*padding-top:10vh;*/
}

.player-wrapper {
  .player-circle {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 50%;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
      left: 0;
      background: #00000047;
      /* background: #505050; */
      background-position: center;
      border: 1px solid $btnprimary;
      background-size: cover;
      /* background: url(http://api.reedioo.com/mediax/cache/channel_image/uploads/images/channels/Cool.jpg);*/
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 0;
      /*background: black;*/
      mix-blend-mode: color;
    }

    .player-subtitle {
      font-size: 0.8vw;
    }

    .player-song {
      margin-top: 16% !important;
      font-size: 0.8vw;
      width: 50vw;

      .song-ellipse-cont {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        width: 67%;
        left: 17%;
        border-radius: 2rem;
        color: white;

        span {
          animation: 10s linear slide-right-lg infinite;
          display: inline-block;
        }
      }
    }

    .player-play {
      margin-top: 19%;
      margin-bottom: 2%;
      height: 6.4vw;
      position: relative;

      &:before {
        position: absolute;
        width: 5vw;
        height: 5vw;
        content: "";
        background: #00000073;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 0.1vw solid $btnprimary;
      }

      > div {
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        left: 0.4vw;
      }

      .play-icon {
        width: 0;
        height: 0;
        border-top: 1.7vw solid transparent;
        border-left: 2.4vw solid $btnprimary;
        border-bottom: 1.7vw solid transparent;
        margin: auto;
      }

      .pause-icon {
        width: 20%;
        left: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
      }

      .pause-icon:before,
      .pause-icon:after {
        content: "";
        width: 0.4vw;
        height: 100%;
        background: $primary;
        position: absolute;
      }

      .pause-icon:before {
        left: 24%;
      }

      .pause-icon:after {
        left: 64%;
      }
    }

    .player-content {
      z-index: 1;

      &:before {
        content: "";
        width: 50%;
        height: 100%;
        float: left;
        shape-outside: polygon(0 0, 98% 0, 50% 6%, 23.4% 17.3%, 6% 32.6%, 0 50%, 6% 65.6%, 23.4% 82.7%, 50% 94%, 98% 100%, 0 100%);
        shape-margin: 2%;
      }

      & > div:before {
        content: "";
        width: 50%;
        height: 100%;
        float: right;
        shape-outside: polygon(
            2% 0%,
            100% 0%,
            100% 100%,
            2% 100%,
            50% 94%,
            76.6% 82.7%,
            94% 65.6%,
            100% 50%,
            94% 32.6%,
            76.6% 17.3%,
            50% 6%
        );
        shape-margin: 2%;
      }
    }

    .player-title {
      font-size: 1.2vw;
      padding-top: 12%;

      .title-ellipse {
        white-space: nowrap;
        /* overflow: hidden; */
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        width: 50%;
        margin: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        text-shadow: 0px 0px 1vw #525252;
        color: white;
      }
    }

    .next-icon,
    .prev-icon {
      width: 1.5vw;
      position: absolute;
      height: 2vw;

      &:before {
        position: absolute;
        width: 3vw;
        height: 3vw;
        content: "";

        top: -35%;
        right: -40%;
        border-radius: 50%;
        z-index: -1;
      }
    }

    .next-icon {
      right: 16%;
      top: 43%;

      svg {
        overflow: visible;
      }

      &:before {
        top: -35%;
        right: -40%;
      }
    }

    .prev-icon {
      left: 16%;
      top: 43%;

      svg {
        top: 14%;
        position: relative;
      }

      &:before {
        top: -35%;
        left: -40%;
      }
    }

    .prev-icon.prev svg {
      transform: rotate(180deg) translateX(50%);
    }

    .next-icon *,
    .prev-icon * {
      fill: white;
    }

    .progress-cont {
      width: 13.5vw;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      height: auto !important;
      position: fixed;
      left: 5.7vw;

      &__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }

      .progress__time {
        font-size: 0.9vw;
        margin-bottom: -2%;

        border-radius: 2rem;
        color: white;

        > span:first-of-type {
          display: inline-block;
          position: absolute;
          left: 29%;
        }

        > span:last-of-type {
          display: inline-block;
          left: 12%;
        }
      }

      &__duration {
        color: #71829e;
        font-weight: 700;
        font-size: 20px;
        opacity: 0.5;
      }

      &__time {
        margin-top: 2px;
        color: #71829e;
        font-weight: 700;
        font-size: 16px;
        opacity: 0.7;
      }
    }

    .progress__bar {
      height: 6px;
      width: 100%;
      cursor: pointer;
      background-color: #d8d8d8;
      display: inline-block;
      border-radius: 10px;
    }

    .progress__current {
      height: inherit;
      width: 0%;
      background-color: $primary;
      border-radius: 10px;
    }
  }

  .logo-img {
    width: 9vw;
  }

  .clock {
    width: 70%;
    margin: auto;
    width: 70%;
    background: #00000047;
    margin: auto;
    color: #f3f3f3;

    .b-aspect {
      align-items: center;
      text-align: center;
      font-size: 2.8vw;
      font-weight: 300;
    }
  }

  .player-placeholder {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .inactive {
    .play-icon {
      border-left: 2.7vw solid #afafaf !important;
    }

    .pause-icon:before,
    .pause-icon:after {
      background: #afafaf !important;
    }

    .next-icon *,
    .prev-icon * {
      fill: #afafaf !important;
    }

    .progress__time {
      opacity: 0;
    }

    .title-ellipse {
      display: none;
    }
  }
}

@keyframes slide-right {
  from {
    transform: translateX(calc(100vw + 100%));
  }
  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-right {
  from {
    transform: translateX(calc(100vw + 100%));
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slide-right-lg {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-right-lg {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.vz-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  background: -webkit-gradient(radial, center center, 0, center center, 460, from(#396362), to(#000000));
  background: -webkit-radial-gradient(circle, #396362, #000000);
  background: -moz-radial-gradient(circle, #396362, #000000);
  background: -ms-radial-gradient(circle, #396362, #000000);
  box-shadow: inset 0 0 160px 0 #000;
  cursor: pointer;
}

.vz-wrapper.-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: initial;
  width: initial;
  background: transparent;
  box-shadow: none;
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0000001a;
}

@media screen and (min-width: 420px) {
  .vz-wrapper {
    box-shadow: inset 0 0 200px 60px #000;
  }
}

@media (max-width: 991px) {
  .player-wrapper {
    .player-bg {
      top: 0;
      padding-top: 0;
    }

    .player-circle {
      .player-play {
        height: 21vw;
        margin-top: 23%;

        &:before {
          width: 23vw;
          height: 23vw;
        }

        .play-icon {
          width: 0;
          height: 0;
          border-top: 8vw solid transparent;
          border-left: 10vw solid $btnprimary;
          border-bottom: 8vw solid transparent;
          margin: auto;
        }

        .pause-icon {
          height: 70%;
          left: 50%;

          &:before {
            left: 15px !important;
          }

          &:after {
            right: 15px !important;
          }
        }

        .pause-icon:before,
        .pause-icon:after {
          width: 1vw;
        }
      }

      .next-icon,
      .prev-icon {
        width: 7vw;
        height: 7vw;
        overflow: visible;
        top: 48%;
      }

      .next-icon {
        top: 45%;
      }

      .player-title {
        font-size: 5vw;
      }

      .player-song {
        font-size: 3vw;
      }
    }

    &.player-hidden {
      .player-circle {
        .player-play {
          .pause-icon {
            width: 100%;

            &:before {
              left: 6px !important;
            }
          }
        }
      }
    }

    .inactive .play-icon {
      border-left: 10vw solid #afafaf !important;
    }

    .logo-cont {
      padding-top: 100px !important;

      .logo-img {
        width: 200px;
        max-width: 90vw;
      }
    }

    .down-icon {
      position: fixed;
      top: 110px;
      left: 8px;
    }
  }
  .mirrory {
    transform: rotate(180deg);
  }

  .player-hidden-text {
    position: relative;
    transform: translateY(-50%);
    top: 85px;
    padding-top: 7px;
    width: calc(100% - 20px);
    color: white;
    right: 0;
    height: 50px;

    .player-song {
      font-size: 15px;
      position: relative;
      width: 100%;
      height: 50px;

      .song-ellipse-cont {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        position: absolute;
        width: 100%;
        height: 50px;

        span {
          animation: 10s linear slide-right infinite;
          display: inline-block;
        }
      }
    }
  }
}
</style>
